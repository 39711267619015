
import { Box, Button, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import QRCode from 'qrcode.react'
import LoadingIndicator from '../Order/Tabs/Loading'
import Container from '../Reusable/Container'
import Fixed from '../Reusable/Fixed'
import PageHeading from '../Reusable/PageHeading'
import PageTitle from '../Reusable/PageTitle'
import Scrollable from '../Reusable/Scrollable'
import useStateValue from '../../hooks/useStateValue'
import routes from '../../routes/route_contants'
import useLogout from '../../hooks/useLogout'
import { PaletteColors } from '../../config/colors'
import IconButton from '../Icon/IconButton'
import downloadQR from '../../helpers/downloadQR'
import useAPILocal from '../../hooks/useAPILocal'
import userAPI from '../../api/user'
import LogoPlaceholderTenant from '../../assets/logoPlaceholderTenant.png'
import { formatContactNo } from '../../helpers/util'

function Profile({ qrValue }) {
  const logout = useLogout()
  const [image, setImage] = useState()
  const history = useHistory()
  const userInfo = useAPILocal(userAPI.info)
  const [{ user }, dispatch] = useStateValue()
  const { user_info } = user
  const [userDetails, setUserDetails] = useState()

  useEffect(() => {
    userInfo.request(user_info.user_id).then(res => setUserDetails(res?.data))
  }, [])

  // const base64ToString = Buffer.from(
  //   userDetails?.profile_picture,
  //   'base64'
  // ).toString()

  return (
    <>
      <>
        <Container pt={3}>
          <Fixed px={3}>
            <PageHeading mb={2}>
              <PageTitle title="Profile" />
              <Box className="flex-row">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: '15px' }}
                  // size="small"
                  onClick={() => history.push(routes.CHANGE_PASSWORD)}
                >
                  Change Password
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: '15px' }}
                  // size="small"
                  onClick={() =>
                    history.push(routes.PROFILE_EDIT, {
                      data: userDetails,
                    })
                  }
                >
                  Edit Profile
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  // size="small"
                  onClick={logout}
                >
                  Logout
                </Button>
              </Box>
            </PageHeading>
          </Fixed>
          <Scrollable p={3}>
            <Box
              className="shipments-list"
              style={{
                padding: 40,
                backgroundColor: PaletteColors.backgroundPrimary,
                borderRadius: 9,
              }}
            >
              {userInfo.isPending ? (
                <LoadingIndicator m={4} />
              ) : (
                //   fields.map(field => (
                <Box className="profilelog">
                  <Box className="avatarwithname">
                    <Box>
                      <img
                        alt=""
                        width="100"
                        height="100"
                        style={{ borderRadius: '50%' }}
                        // src={
                        //   userDetails?.profile_picture || LogoPlaceholderTenant
                        // }
                        src={`${process.env.REACT_APP_API_URL}/user.profile.picture/${userDetails?.user_id}`}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null
                          currentTarget.src = LogoPlaceholderTenant
                        }}
                      />
                    </Box>
                    <Box className="name-role">
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          fontSize: 18,
                          color: PaletteColors.textDark,
                        }}
                        variant="body1"
                      >
                        {`${userDetails?.first_name} ${userDetails?.last_name}`}
                      </Typography>
                      <Typography style={{ fontSize: 16, color: '#3298ff' }}>
                        {userDetails?.role?.role_name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="profileemail">
                    <Typography
                      style={{
                        fontSize: 16,
                        color: PaletteColors.textMedium,
                      }}
                      variant="body1"
                    >
                      Email :
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 16,
                        marginLeft: 5,
                        color: PaletteColors.textDark,
                      }}
                      variant="body1"
                    >
                      {userDetails?.email}
                    </Typography>
                  </Box>

                  {/* <Item label={field.label} value={field.value(userInfo.data) || 'arpit'} /> */}
                </Box>
                //   ))
              )}
            </Box>
            {userInfo.isPending ? (
              <LoadingIndicator m={4} />
            ) : (
              <Box mt={5}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box>
                      <PageTitle title="Personal Details" />
                    </Box>
                  </Grid>
                  <Grid item container xs={12} style={{ marginTop: '40px' }}>
                    <Grid item container xs={5}>
                      <Grid xs={6}>
                        <label className="fieldsuser">First Name</label>
                      </Grid>
                      <Grid xs={1}>:</Grid>
                      <Grid xs={5}>
                        <p>{userDetails?.first_name}</p>
                      </Grid>
                      <Grid xs={6} className="gridmargin">
                        <label className="fieldsuser">Last Name</label>
                      </Grid>
                      <Grid xs={1} className="gridmargin">
                        :
                      </Grid>
                      <Grid xs={5} className="gridmargin">
                        <p>{userDetails?.last_name}</p>
                      </Grid>
                      <Grid xs={6} className="gridmargin">
                        <label className="fieldsuser">Mobile Number</label>
                      </Grid>
                      <Grid xs={1} className="gridmargin">
                        :
                      </Grid>
                      <Grid xs={5} className="gridmargin">
                        <p>{formatContactNo(userDetails?.phone_no)}</p>
                      </Grid>
                      <Grid xs={6} className="gridmargin">
                        <label className="fieldsuser">Username</label>
                      </Grid>
                      <Grid xs={1} className="gridmargin">
                        :
                      </Grid>
                      <Grid xs={5} className="gridmargin">
                        <p>{userDetails?.username}</p>
                      </Grid>
                      <Grid xs={6} className="gridmargin">
                        <label className="fieldsuser">Email</label>
                      </Grid>
                      <Grid xs={1} className="gridmargin">
                        :
                      </Grid>
                      <Grid xs={5} className="gridmargin">
                        <p> {userDetails?.email}</p>
                      </Grid>
                    </Grid>
                    <Grid item container xs={2} className="profileqr">
                      <div className="qr-code">
                        {userDetails !== '' && (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <div className="qr-code-section">
                              <QRCode
                                value={JSON.stringify({
                                  email: userDetails?.email,
                                  id: userDetails?.user_id,
                                  first_name: userDetails?.first_name,
                                  last_name: userDetails?.last_name,
                                })}
                                style={{
                                  width: '100px',
                                  paddingBottom: '10px',
                                }}
                                renderAs="svg"
                              />
                            </div>
                            <div
                              style={{
                                paddingBottom: '35px',
                                color: '#3298FF',
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  downloadQR(
                                    document.querySelector(
                                      '.qr-code-section svg'
                                    ),
                                    [800, 800],
                                    'QR_Company_Profile.png'
                                  )
                                }}
                              >
                                <p>Download QR Code</p>
                              </IconButton>
                            </div>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Scrollable>
        </Container>
      </>
    </>
  )
}

export default Profile
