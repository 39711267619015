import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import { useHistory } from 'react-router-dom'
import TableHead from '../Reusable/TableHead'
import Fixed from '../Reusable/Fixed'
import Scrollable from '../Reusable/Scrollable'
import TableBody from '../Reusable/TableBody'
import Container from '../Reusable/Container'
import { returnValue } from './utils'
import { formatLocation } from '../../helpers/util'
import Loading from '../Order/Tabs/Loading'
import { PaletteColors } from '../../config/colors'
import Tankl from '../../assets/TankList.png'
import routes from '../../routes/route_contants'

const columns = [
  {
    label: 'Reg. No.',
    value: returnValue('tankRegistrationNo'),
    minWidth: 180,
  },
  {
    label: 'Product Details',
    value: e => {
      return (
        <Box>
          <Box style={{ color: PaletteColors.textDark }}>
            {e.chemical?.description}
          </Box>
          {/* <Box style={{ color: PaletteColors.textLight }}>
            Product ID:{e.chemical?.product_id}
          </Box> */}
        </Box>
      )
    },
  },
  // {
  //   label: 'City',
  //   value: e => e.location?.city,
  // },
  {
    label: 'Type',
    value: e => {
      const maps = { loadingTank: 'Loading', unloadingTank: 'Unloading' }
      return Object.keys(maps)
        .filter(r => e[r])
        .map(p => maps[p])
        .join(' / ')
    },
  },
  {
    label: 'QR Code',
    value: e => {
      return (
        <img
          alt="qr"
          src={Tankl}
          style={{ width: '3rem' }}
          onClick={event => {
            event.stopPropagation()
            // handleOpenDialog()
          }}
        />
      )
    },
    maxWidth: 300,
  },
]

const customer_tank_columns = [
  columns[0],
  columns[1],

  // {
  //   label: 'City',
  //   value: e => e.customer.customer_location.city,
  // },
  { ...columns[3], label: 'Type' },
  { label: 'QR code', value: e => 2 },
]

function Tanks({ type, apiResult, selectable, onSelectRows }) {
  const history = useHistory()
  const [selectedAll, setSelectedAll] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  let col = columns
  if (type === 'partner') {
    col = [...col[0], ...col.slice(2)]
  }

  useEffect(() => {
    setSelectedRows(prev => prev.map(e => selectedAll))
  }, [selectedAll])

  useEffect(() => {
    if (apiResult.isResolved)
      setSelectedRows(apiResult.data?.results?.map(e => false))
  }, [apiResult.isResolved])
  return (
    <Container>
      <Scrollable>
        <TableHead
          columns={type === 'tenant' ? columns : customer_tank_columns}
        />
        {apiResult.isPending ? (
          <Loading m={4} />
        ) : (
          <TableBody
            columns={type === 'tenant' ? columns : customer_tank_columns}
            data={apiResult.data?.results}
            rowOnClick={row =>
              history.push(`${routes.TANK_DETAILS}/${row.tankRegistrationNo}`)
            }
          />
        )}
      </Scrollable>
    </Container>
  )
}

Tanks.propTypes = {
  type: PropTypes.string,
  apiResult: PropTypes.object,
}

Tanks.defaultProps = {
  type: 'tenant',
  apiResult: {},
}

export default Tanks
