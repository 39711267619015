import { formatContactNo, formatLocation } from './util'

const template1 = (
  image,
  data
) => `<div style="display:flex;flex-direction:row;padding:20px;width:4in;align-items:center;" id="templateLabel">
        <div style="margin-bottom:10px;margin-top:10px">${image}</div> 
          <div style="margin-left:20px;"> 
           <p class="label-text-tank"><b>Product Name:&nbsp; &nbsp;${
             data?.chemical
               ? data?.chemical?.name || data?.chemical?.description
               : data?.products?.map(item => item?.name)?.join(', ') || 'N/A'
           }</b><br/>
            <p class="label-text-tank"><b>Product Code:&nbsp; &nbsp;${
              data?.chemical
                ? data?.chemical?.unNumber || data?.chemical?.chem_un
                : data?.products
                    ?.map(item => item?.unNumber || 'N/A')
                    ?.join(', ') || 'N/A'
            }</p>   
          </div>
    </div>
`

const template3 =
  image => `<div style="display:flex;padding:20px;width:4in" id="templateLabel">
        <div style="width:100%">
        <div style="margin-bottom:10px;margin-top:10px">${image}</div>
        </div>
        </div>`

const template2 = (image, data) =>
  `<div style="display:flex;padding:20px;width:8in;flex-direction:column;" id="templateLabel">
    <div style="display:flex;flex-direction:row;margin-left:40px;">
      <div style="max-width:50%">
            <img style="width:120px;height:25px;" src="${
              process.env.PUBLIC_URL
            }/images/cargocheck.jpg"/>
            <div style="margin-bottom:10px;margin-top:10px">${image}</div>
      </div>
      <div style="flex:1;padding:10px;margin-left:20px;margin-top:20px;">
        <p class="label-text-tank"><b>${data?.tankRegistrationNo}</b><br/>
         <p class="label-text-tank"><b>Company:&nbsp; &nbsp;N/A</p>
        <p class="label-text-tank"><b>Address:&nbsp; &nbsp; ${`${data.location.city}, ${data.location.state.name}`}</p>
      </div>
    </div>
   
    <div style="flex:1;padding:5px;margin-left:20px">
      <div style="display:flex;flex-direction:row;align-items:center;">
        <p style="font-size:24px;"><b>Product Name: &nbsp; </b><br/>
        <h2>${data?.products.map(item => item.name).join(',') || 'N/A'}</h2>
      </div>
        
      <div style="display:flex;flex-direction:row;align-items:center;">
        <p style="font-size:24px;"><b>UN Product Name: &nbsp; </b><br/>
        <h2>${
          data?.chemical
            ? data?.chemical?.name || data?.chemical?.description
            : data?.products?.[0]?.chemical?.name || 'N/A'
        }</h2>
      </div>
        
      <div style="display:flex;flex-direction:row;align-items:center;">
        <p style="font-size:24px;"><b>UN Number: &nbsp; </b><br/>
        <h2>${
          data?.chemical
            ? data?.chemical?.unNumber || data?.chemical?.chem_un
            : data?.products?.[0]?.chemical?.unNumber || 'N/A'
        }</h2>
      </div>
        
    </div>
     <div style="flex:1;padding:10px;margin-left:20px;margin-top:40px;">
        <p style="font-size:20px;">Scan code for SDS or to confirm BOL matches this tank.</br>
        Call ${
          formatContactNo(data.facilityPhoneNoForDriverAssistance) ||
          formatContactNo(data.primaryContact) ||
          '-'
        } if BOL product does not match this tank.<br/>
        </p>
    </div>
</div>

<style>
.label-text-tank{
line-height:30px;
margin-bottom:10px;
font-size:20px
}
</style>`

export default function LabelTemplate(image, data, templateNo = 1) {
  switch (templateNo) {
    case 1:
      return template1(image, data)
    case 2:
      return template2(image, data)
    case 3:
      return template3(image)
    default:
      return template2(image, data)
  }
}
