import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import useRole from '../../hooks/useRole'
import Modal from '../Modal'
import useForm from '../../hooks/useForm'
import Loading from '../Loading'
import useAPILocal from '../../hooks/useAPILocal'
import bolAPI from '../../api/bol'
import productAPI from '../../api/product'
import TextInput from '../Reusable/Widgets/TextInput'
import SelectInput from '../Reusable/Widgets/SelectInput'
import AutoCompleteInput from '../Reusable/Widgets/AutoCompleteInput'
import tankAPI from '../../api/tank'
import { Alert, Snackbar } from '@mui/material'

const init = {
  shippedQty: '',
  orderedQty: '',
  qtyUnit: '',
}

const validationSchema = {}

const OrderItemUpdate = ({ data, onClose, visible, onDone }) => {
  const { role } = useRole()
  const [loadingTanks, setLoadingTanks] = useState([])
  const [unloadingTanks, setUnloadingTanks] = useState([])
  const updateProduct = useAPILocal(bolAPI.updateProduct)
  const getLoadingTanks = useAPILocal(tankAPI.getLoadingTanks)
  const getUnloadingTanks = useAPILocal(tankAPI.getUnloadingTanks)

  useEffect(() => {
    getLoadingTanks.request({ bolItemId: data?.bolItemId }).then(res =>
      setLoadingTanks(
        res?.data?.results?.map(({ tank_registration_no }) => {
          const newArray = {
            value: tank_registration_no,
            label: tank_registration_no,
          }
          return newArray
        })
      )
    )
    getUnloadingTanks.request({ bolItemId: data?.bolItemId }).then(res =>
      setUnloadingTanks(
        res?.data?.results?.map(({ tank_registration_no }) => {
          const newArray = {
            value: tank_registration_no,
            label: tank_registration_no,
          }
          return newArray
        })
      )
    )
  }, [])

  const { errors, handleOnChange, values, handleSubmit, resetForm, setValues } =
    useForm({ ...init }, validationSchema, async result => {
      const resultObj = {
        orderedQty: result.orderedQty,
        qtyUnit: result.qtyUnit,
        shippedQty: result.shippedQty,
        productId: result.productId?.id,
        mandatoryLoadingTank: result.mandatoryLoading || null,
        mandatoryUnloadingTank: result.mandatoryUnloading || null,
      }
      updateProduct.request(data?.bolItemId, resultObj)
    })

  useEffect(() => {
    setValues({
      productId: data?.product?.name,
      shippedQty: data?.shippedQty,
      orderedQty: data?.orderedQty,
      qtyUnit: data?.qtyUnit,
      mandatoryUnloading: data?.mandatoryUnloadingTank,
      mandatoryLoading: data?.mandatoryLoadingTank,
    })
  }, [data])

  useEffect(() => {
    if (updateProduct.isResolved) {
      resetForm()
      onDone()
      setTimeout(() => {
        updateProduct.reset()
        onClose()
      }, 500)
    } else if (updateProduct.isRejected) {
      setTimeout(() => {
        updateProduct.reset()
      }, 1400)
    }
  }, [updateProduct.isResolved, updateProduct.isRejected])

  const additionalProps = name => {
    return {
      value: values[name],
      error: errors[name],
      onChange: newValue => {
        handleOnChange({
          name,
          value: newValue,
        })
      },
    }
  }

  return (
    <Modal visible={visible} onClose={onClose}>
      {role.admin && (
        <>
          <AutoCompleteInput
            flex="15"
            label="Product"
            value={values?.productId}
            api={{
              src: productAPI.list,
            }}
            renderItem={option => option?.name}
            {...additionalProps('productId')}
          />

          <TextInput
            label="Shipped Quantity"
            type="text"
            placeholder="Shipped Quantity"
            onChange={e => {
              return handleOnChange({
                name: 'shippedQty',
                value: e.target?.value,
              })
            }}
            value={values?.shippedQty}
            error={errors?.shippedQty}
          />
          <TextInput
            label="Ordered Quantity"
            type="text"
            placeholder="Ordered Quantity"
            onChange={e => {
              return handleOnChange({
                name: 'orderedQty',
                value: e.target?.value,
              })
            }}
            value={values?.orderedQty}
            error={errors?.orderedQty}
          />
          <SelectInput
            emptyLabel="Select a unit"
            label="Quantity Unit"
            name="qtyUnit"
            options={[
              { value: 'gallons', label: 'Gallons' },
              { value: 'liters', label: 'Liters' },
              { value: 'wet_tons', label: 'Wet tons' },
              { value: 'pounds', label: 'Pounds' },
              { value: 'kg', label: 'Kilo grams' },
              { value: 'metric_tons', label: 'Metric tons' },
            ]}
            onChange={e => {
              return handleOnChange({ name: 'qtyUnit', value: e })
            }}
            value={values?.qtyUnit || ''}
          />

          <SelectInput
            label="Mandatory Loading Tank"
            name="mandatoryLoading"
            options={loadingTanks}
            onChange={e => {
              return handleOnChange({ name: 'mandatoryLoading', value: e })
            }}
            value={values?.mandatoryLoading || ''}
          />
          <SelectInput
            label="Mandatory Unloading Tank"
            name="mandatoryUnloading"
            options={unloadingTanks}
            onChange={e => {
              return handleOnChange({ name: 'mandatoryUnloading', value: e })
            }}
            value={values?.mandatoryUnloading || ''}
          />
        </>
      )}

      <Button
        disabled={updateProduct.isPending}
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        style={{ marginTop: '10px' }}
      >
        {updateProduct.isPending ? <Loading /> : ' Update Order'}
      </Button>
      <Snackbar
        open={updateProduct.isRejected}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error">
          {updateProduct?.error || 'Error Updating Order Details'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={updateProduct.isResolved}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success">Product Order Updated</Alert>
      </Snackbar>
    </Modal>
  )
}

OrderItemUpdate.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onDone: PropTypes.func.isRequired,
}

export default OrderItemUpdate
