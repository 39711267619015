import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import QRCode from 'qrcode.react'
import {
  Button,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Alert } from '@mui/material'
import routes from '../../routes/route_contants'
import './TankDetailsPage.scss'
import Loading from '../Loading'
import downloadTankLabel from '../../helpers/downloadTankLabel'
import Container from '../Reusable/Container'
import Fixed from '../Reusable/Fixed'
import PageHeading from '../Reusable/PageHeading'
import PageTitle from '../Reusable/PageTitle'
import Scrollable from '../Reusable/Scrollable'
import { PaletteColors } from '../../config/colors'
import LoadingIndicator from '../Order/Tabs/Loading'
import { formatContactNo, formatLocation } from '../../helpers/util'
import './TankDetailsPage.scss'
import Modal from '../Modal'
import tankAPI from '../../api/tank'
import useAPILocal from '../../hooks/useAPILocal'
import fileAPI from '../../api/file'
import useRole from '../../hooks/useRole'

const fields = [
  { label: 'Tank Name/Number', value: e => e?.tankRegistrationNo },
  { label: 'Universal Product Name', value: e => e?.chemical?.name },
  {
    label: 'Partner Name',
    value: e => (e?.customer?.customer_name ? e?.customer?.customer_name : '-'),
  },
  {
    label: 'Location',
    value: e =>
      formatLocation(
        e?.location,
        'location_name postal_address city country postal_code'
      ),
  },

  {
    label: 'Contact No.',
    value: e => formatContactNo(e?.facilityPhoneNoForDriverAssistance),
  },
  {
    label: 'Loading/Unloading',
    value: e => {
      if (e?.loadingTank === true && e?.unloadingTank === false)
        return 'Only Loading'
      if (e?.loadingTank === false && e?.unloadingTank === true)
        return 'Only Unloading'
      if (e?.loadingTank === true && e?.unloadingTank === true)
        return 'Loading & Unloading'
    },
  },
  {
    label: 'Is Public',
    value: e => (e?.public === true ? 'Public Tank' : 'Private Tank'),
  },
  {
    label: 'Primary Contact',
    value: e =>
      e?.primaryContact !== null
        ? `${e?.primaryContact?.name} ${e?.primaryContact?.surname}`
        : '-',
  },
  {
    label: 'Primary Contact Phone No.',
    value: e =>
      e?.primaryContact !== null
        ? formatContactNo(e?.primaryContact?.phone)
        : '-',
  },
]
const Item = ({ label, value, right = false, ...rest }) => {
  return (
    <Box>
      <Box>
        <Typography
          style={{
            fontSize: 14,
            color: PaletteColors.textMedium,
            wordBreak: 'break-all',
          }}
          variant="caption"
        >
          {label}
        </Typography>
        <Typography
          style={{ fontSize: 16, color: PaletteColors.textDark }}
          variant="body1"
        >
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

const TabsArray = [
  { text: 'Tank Details', name: 'tankdetails' },
  // { text: 'Checklist', name: 'checklist' },
  // { text: 'Photos & Comments', name: 'photosandcomments' },
]
const SafetyDetails = [
  {
    safety: 'Is the Level indicator visible to driver',
    name: 'levelIndicator',
  },
  {
    safety: 'Safety shower is visible from Offloading area?',
    name: 'showerVisibleFromOffloading',
  },
  {
    safety: 'The path to the safety shower is free of obstructions?',
    name: 'pathToShowerFreeFromObstruction',
  },
  {
    safety: 'Offloading pipe labelled with Chemical Name or UN #',
    name: 'offloadingPipeLabeledWithChemicalUn',
  },
  {
    safety: 'Offloading point’s distance from safety shower',
    name: 'unloadingToShowerDistance',
    unit: 'unloadingToShowerDistanceUnit',
  },
  { safety: 'Standard PPE', name: 'standardPpe' },
  { safety: 'Special PPE', name: 'specialPpe' },
  { safety: 'Spill Management Plan', name: 'spillManagementPlanAvailable' },
  {
    safety: 'Tank has shared containment?',
    name: 'tankHasSharedContainment',
  },
]
const StorageTankDetails = [
  { storage: 'Material of Construction', name: 'materialOfConstruction' },
  { storage: 'Usable Volume', name: 'usableVolume', unit: 'units' },
  {
    storage: 'Typical Delivery Volume',
    name: 'typicalDeliveryVolume',
    unit: 'typicalDeliveryVolumeUnit',
  },
  { storage: 'Tank lining', name: 'tankLining' },
  { storage: 'Fill Entry Point', name: 'fillEntryPoint' },
  {
    storage: 'Fill Pipe Diameter',
    name: 'fillPipeDiameter',
    unit: 'fillPipeDiameterUnit',
  },
  {
    storage: 'Tank Vent Diameter',
    name: 'tankVentDiameter',
    unit: 'tankVentDiameterUnit',
  },
  {
    storage: 'Other Openings That Vent',
    name: 'otherOpeningsThatVent',
    unit: 'otherOpeningsThatVentUnit',
  },
  { storage: 'Last Inspection Date', name: 'lastInspectionDate' },
]

const TankTrailerDetails = [
  {
    tank: 'Who is responsible for spill management plan ?',
    name: 'spillManagementPlanAvailable',
  },
  {
    tank: 'Does someone stay with driver during offloading ?',
    name: 'doesSomeoneStayWithDriverDuringOffloading',
  },
  {
    tank: 'Plant Side Connection',
    name: 'plantSideConnection',
  },
  {
    tank: 'Unloading Method',
    name: 'unloadingMethod',
  },
  {
    tank: 'Delivery Connection Size',
    name: 'deliveryConnectionSize',
    unit: 'deliveryConnectionSizeUnit',
  },
  {
    tank: 'Vertical Lift ',
    name: 'verticalLiftRequired',
    unit: 'verticalLiftRequiredUnit',
  },
  {
    tank: 'Is air regulated to less than 30 Psi ?',
    name: 'airRegulatedToLessThan30Psi',
  },
  { tank: 'Is the air filtered ?', name: 'isTheAirFiltered' },
  {
    tank: 'Procedure to prevent trailer collapse',
    name: 'procedureToPreventTrailerCollapse',
  },
]

export default function TankDetailsPage() {
  const [FullScreenOpen, SetFullScreenOpen] = useState(false)
  const [sdsModal, setSdsModal] = useState(false)
  const [sdsPresent, setSdsPresent] = useState(true)
  const [tankInfo, setTankInfo] = useState([])
  const [sdsSheet, setSdsSheet] = useState()
  const [open, setOpen] = useState(false)
  const [tab, setTab] = useState(TabsArray[0].name)
  const history = useHistory()
  const { role } = useRole()
  const path = useParams()
  const qrValue = {
    tank_registration_no: path.tank_registration_no,
  }
  const tankInfoApi = useAPILocal(tankAPI.info)
  const getSDSFile = useAPILocal(tankAPI.getFile)
  const getFile = useAPILocal(fileAPI.getFile)
  const deleteTank = useAPILocal(tankAPI.deleteTank)
  const getTankInfo = async () => {
    tankInfoApi.request(path.tank_registration_no)
  }
  const [openSnack, setOpenSnack] = React.useState(false)

  const editTankInfoData = () => {
    history.push({
      pathname: `${routes.UPDATE_TANK}/${tankInfo.tankRegistrationNo}`,
      state: {
        mode: 'update',
        details: tankInfoApi.data,
      },
    })
  }

  const handlefullscreenclose = () => {
    SetFullScreenOpen(false)
  }

  useEffect(() => {
    getSDSFile.request(path.tank_registration_no).then(res => {
      if (res?.data.length > 0) setSdsPresent(true)
      else setSdsPresent(false)
      if (res?.data[0]?.id)
        getFile
          .request(res?.data[0]?.id)
          .then(response => setSdsSheet(response.data))
    })
  }, [])

  useEffect(() => {
    getTankInfo()
  }, [path.tank_registration_no])

  useEffect(() => {
    if (!tankInfoApi.data) return
    setTankInfo(tankInfoApi.data)
  }, [tankInfoApi.isResolved])

  if (!tankInfoApi.isResolved) {
    return <Loading fullScreen />
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenSnack(false)
  }

  return (
    <Container pt={3}>
      <Fixed px={3}>
        <PageHeading mb={2}>
          <PageTitle backButton title={tankInfo?.tankRegistrationNo} />
          {role?.admin && (
            <div>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  color: 'red',
                  borderColor: 'red',
                  marginRight: '10px',
                }}
                onClick={() => setOpen(true)}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={editTankInfoData}
              >
                Edit Details
              </Button>
            </div>
          )}
          {open && (
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to delete this tank ?
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                  No
                </Button>
                <Button
                  onClick={() => {
                    deleteTank.request(path.tank_registration_no).then(() => {
                      if (deleteTank?.isRejected) setOpen(false)
                      else if (deleteTank?.status === 'idle') {
                        setOpen(false)
                      }
                      setTimeout(() => {
                        history.push(routes.TANKS_LIST)
                      }, 3000)
                    })
                  }}
                  color="primary"
                >
                  {deleteTank.isPending ? <LoadingIndicator /> : 'Yes'}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </PageHeading>
      </Fixed>
      <Scrollable p={3}>
        <Box
          className="shipments-list"
          style={{
            // paddingTop: 30,
            paddingLeft: 30,
            paddingBottom: 28,
            backgroundColor: PaletteColors.backgroundPrimary,
            borderRadius: 9,
          }}
        >
          <div className="tankbox">
            <div className="tankboxin">
              {tankInfo.isPending ? (
                <LoadingIndicator m={4} />
              ) : (
                fields.map(field => (
                  <Box key={field.label}>
                    <Item label={field.label} value={field.value(tankInfo)} />
                  </Box>
                ))
              )}
            </div>
            {sdsPresent && (
              <div style={{ flex: '10%', paddingTop: '30px' }}>
                <div>SDS Sheet</div>
                <Typography
                  style={{
                    fontSize: '15px',
                    lineHeight: '18px',
                    fontFamily: 'Lato',
                    fontWeight: 500,
                    color: '#3298FF',
                    marginTop: '10px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSdsModal(true)}
                >
                  View File
                </Typography>
                <Modal
                  visible={sdsModal}
                  onClose={() => setSdsModal(false)}
                  style={{ minWidth: '250px', height: '600px' }}
                >
                  <Grid container justifyContent="center" direction="column">
                    {getFile?.isPending ? (
                      <LoadingIndicator />
                    ) : (
                      <embed
                        height="600px"
                        src={sdsSheet}
                        type="application/pdf"
                        width="100%"
                      ></embed>
                    )}
                  </Grid>
                </Modal>
              </div>
            )}

            <div style={{ flex: '20%' }}>
              <div className="qr-code-section">
                <QRCode
                  value={JSON.stringify({
                    ...qrValue,
                    product_id: tankInfo.chemical?.product_id,
                    customer_no: tankInfo.customer?.customer_no,
                    chemical: {
                      name: tankInfo.chemical?.name,
                      un_number: tankInfo.chemical?.unNumber,
                      hazard_class: tankInfo.chemical?.hazardClass,
                    },
                    products: tankInfo?.products?.map(item => ({
                      name: item.name,
                      un_number: item.unNumber,
                      code: item.code,
                    })),
                  })}
                  style={{ height: '120px', width: '120px', margin: 'auto' }}
                  renderAs="svg"
                  className="qrr-code"
                  onClick={() => SetFullScreenOpen(true)}
                />
              </div>
              <Modal
                visible={FullScreenOpen}
                onClose={handlefullscreenclose}
                style={{ minWidth: '250px' }}
              >
                <Grid container justifyContent="center" direction="column">
                  <Grid item>
                    <Grid container direction="row" justifyContent="center">
                      <QRCode
                        value={JSON.stringify({
                          ...qrValue,
                          product_id: tankInfo.chemical?.product_id,
                          customer_no: tankInfo.customer?.customer_no,
                          chemical: {
                            name: tankInfo.chemical?.name,
                            un_number: tankInfo.chemical?.unNumber,
                            hazard_class: tankInfo.chemical?.hazardClass,
                          },
                          products: tankInfo?.products?.map(item => ({
                            name: item.name,
                            un_number: item.unNumber,
                            code: item.code,
                          })),
                        })}
                        style={{
                          height: '200px',
                          width: '200px',
                          margin: 'auto',
                        }}
                        renderAs="svg"
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" justifyContent="center">
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            marginTop: '20px',
                            marginRight: '15px',
                          }}
                          onClick={() => {
                            downloadTankLabel(
                              document.querySelector('.qr-code-section svg')
                                .outerHTML,
                              tankInfo,
                              1
                            )
                          }}
                        >
                          Download QR Only
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{
                            marginTop: '20px',
                            marginRight: '15px',
                          }}
                          onClick={() => {
                            downloadTankLabel(
                              document.querySelector('.qr-code-section svg')
                                .outerHTML,
                              tankInfo,
                              2
                            )
                          }}
                        >
                          Download Full Preview
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Modal>
            </div>
          </div>
        </Box>
        <div style={{ marginTop: '50px', marginBottom: '24px' }}>
          <div className="containingtanks">Tank Details</div>
        </div>
        <Box className="accordianstank">
          <Box mt={3}>
            {' '}
            <Accordion className="accordian">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="accordianstankhead">
                  Special Instructions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {!tankInfoApi?.data?.specialInstructions
                    ? '-'
                    : tankInfoApi?.data?.specialInstructions || '-'}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box mt={3}>
            <Accordion className="accordian">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="accordianstankhead">
                  Safety Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid spacing={2} container direction="row">
                  {SafetyDetails.map(safety => (
                    <Grid key="sh" item xs={4} spacing={1}>
                      <div className="safetydetailsblocks">
                        <p className="safetydetailsblockstext">
                          {safety.safety}
                        </p>
                        <p className="safetydetailstext">
                          {safety.name === 'spillManagementPlanAvailable' &&
                            safety.name.length > 0 &&
                            'Yes'}
                          {safety.unit &&
                            tankInfoApi?.data?.[safety.name] !== null &&
                            `${
                              tankInfoApi?.data?.[safety.name]
                            } ${tankInfoApi?.data?.[safety.unit]?.replace(
                              '_',
                              ' '
                            )}`}
                          {tankInfoApi?.data?.[safety.name] === true && 'Yes'}
                          {tankInfoApi?.data?.[safety.name] === false && 'No'}
                          {(tankInfoApi?.data?.[safety.name] === null) && 'Not specified'}
                          {typeof tankInfoApi?.data?.[safety.name] !==
                            Boolean &&
                            safety.name !== 'unloadingToShowerDistance' &&
                            safety.name !== 'spillManagementPlanAvailable' &&
                            tankInfoApi?.data?.[safety.name]}
                        </p>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box mt={3}>
            <Accordion className="accordian">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="accordianstankhead">
                  Storage Tank Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid spacing={2} container direction="row">
                  {StorageTankDetails.map(storage => (
                    <Grid key="sh" item xs={4} spacing={1}>
                      <div className="safetydetailsblocks">
                        <p className="safetydetailsblockstext">
                          {storage.storage}
                        </p>
                        <p className="safetydetailstext">
                          {storage.unit &&
                            tankInfoApi?.data?.[storage.name] !== null &&
                            `${
                              tankInfoApi?.data?.[storage.name]
                            } ${tankInfoApi?.data?.[storage.unit]?.replace(
                              '_',
                              ' '
                            )}`}
                          {typeof tankInfoApi?.data?.[storage.name] !==
                            Boolean &&
                            storage.name !== 'usableVolume' &&
                            storage.name !== 'typicalDeliveryVolume' &&
                            storage.name !== 'fillPipeDiameter' &&
                            storage.name !== 'tankVentDiameter' &&
                            storage.name !== 'otherOpeningsThatVent' &&
                            storage.name !== 'tankVentDiameter' &&
                            tankInfoApi?.data?.[storage.name]?.replace(
                              /\_/g,
                              ' '
                            )}
                          {!tankInfoApi?.data?.[storage.name] && '-'}
                          {tankInfoApi?.data?.[storage.name] === true && 'Yes'}
                          {tankInfoApi?.data?.[storage.name] === false && 'No'}
                        </p>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box mt={3}>
            <Accordion className="accordian">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="accordianstankhead">
                  Tank Trailer Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid spacing={2} container direction="row">
                  {TankTrailerDetails?.map(tank => (
                    <Grid key="sh" item xs={4} spacing={1}>
                      <div
                        className="safetydetailsblocks"
                        style={{ overflowY: 'scroll' }}
                      >
                        <p className="safetydetailsblockstext">{tank?.tank}</p>
                        <p className="safetydetailstext">
                          {tank?.unit &&
                            tankInfoApi?.data?.[tank?.name] !== null &&
                            `${
                              tankInfoApi?.data?.[tank?.name]
                            } ${tankInfoApi?.data?.[tank?.unit]?.replace(
                              '_',
                              ' '
                            )}`}
                          {typeof tankInfoApi?.data?.[tank.name] !== Boolean &&
                            tank.name !== 'deliveryConnectionSize' &&
                            tank.name !== 'verticalLiftRequired' &&
                            tank.name !== 'unloadingMethod' &&
                            tank.name !== 'spillManagementPlanAvailable' &&
                            tank.name !==
                              'doesSomeoneStayWithDriverDuringOffloading' &&
                            tankInfoApi?.data?.[tank.name]}
                          {tank.name === 'unloadingMethod' &&
                            tankInfoApi?.data?.[tank.name]?.replace('_', ' ')}
                          {(tank.name === 'spillManagementPlanAvailable' ||
                            tank.name ===
                              'doesSomeoneStayWithDriverDuringOffloading') &&
                            tankInfoApi?.data?.[tank.name]?.map(item => (
                              <p>
                                {item}
                                <br />
                              </p>
                            ))}
                          {!tankInfoApi?.data?.[tank.name] && '-'}
                          {tankInfoApi?.data?.[tank.name] === true && 'Yes'}
                          {tankInfoApi?.data?.[tank.name] === false && 'No'}
                        </p>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        <Snackbar
          open={deleteTank.isRejected}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity="error" onClose={handleCloseSnack}>
            {deleteTank?.data?.message}
          </Alert>
        </Snackbar>
        <Snackbar
          open={deleteTank?.isResolved}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity="success">Tank deleted succesfully</Alert>
        </Snackbar>
      </Scrollable>
    </Container>
  )
}
