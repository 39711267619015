import { useEffect, useState } from 'react'
import useStateValue from './useStateValue'

const useRole = () => {
  const [state] = useStateValue()
  const [role, setRole] = useState({})
  const { user, allRoles } = state

  useEffect(() => {
    if (user?.user_info?.role?.role_id === 0)
      return setRole({ superuser: true })
    if (user?.user_info?.role?.role_id === 1) return setRole({ admin: true })
    setRole({ driver: true })
  }, [user])

  return { role, allRoles, roleObj: user?.user_info?.role }
}

export default useRole
