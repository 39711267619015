export const fields = {
  product: [
    { key: 'Product Code', value: 'PRODUCT_CODE' },
    { key: 'Product Name', value: 'PRODUCT_NAME' },
    { key: 'Product UN Number', value: 'PRODUCT_UN' },
  ],

  customer: [
    { key: 'Customr No.', value: 'CNUM' },
    { key: 'Customer Name', value: 'STNAME' },
    { key: 'Address line 1', value: 'STAD1' },
    { key: 'Address Line 2', value: 'STAD2' },
    { key: 'Address Line 3', value: 'STAD3' },
    { key: 'City', value: 'STCITY' },
    { key: 'State', value: 'STST' },
    { key: 'Zipcode', value: 'STZIP' },
  ],

  shipment: [
    { key: 'Bill Date', value: 'BILL_DATE' },
    { key: 'Shipment Date', value: 'SHIPMENT_DATE' },
    { key: "Diver's Email", value: 'DRIVER_EMAIL' },
    { key: 'Product Code', value: 'PRODUCT_CODE' },
    { key: 'Ordered Qty', value: 'ORDERED_QTY' },
    { key: 'Unit', value: 'UNIT' },
    { key: 'BOL No.', value: 'BOL_NO' },
    { key: 'Shipped Qty', value: 'SHIPPED_QTY' },
    { key: 'Carrier ID', value: 'CARRIER_ID' },
    { key: 'Shipper identification', value: 'SHIPPER_ID' },
    { key: 'Receiver identification', value: 'RECEIVER_ID' },
    { key: 'Customer P.O', value: 'CUSTOMER_PO' },
    { key: 'Loading Location Identification', value: 'LOADING_LOCATION' },
    { key: 'Unloading Location Identification', value: 'UNLOADING_LOCATION' },
    { key: 'Gross Weight', value: 'GROSSWEIGHT', optional: true },
    { key: 'Gross Weight unit', value: 'GROSSWEIGHT_UNIT', optional: true },
    { key: 'Net Weight', value: 'NETWEIGHT', optional: true },
    { key: 'Net Weight unit', value: 'NETWEIGHT_UNIT', optional: true },
  ],

  tank: [
    { key: 'Customer No.', value: 'CUSTOMER_NO' },
    { key: 'Product UN', value: 'PRODUCT_UN' },
    { key: 'Facility No.', value: 'FACILITY_NO' },
  ],
  location: [
    { key: 'Location Name', value: 'LNAME' },
    { key: 'Facility Number', value: 'FACILITY_NUM' },
    { key: 'Address line 1', value: 'STAD1' },
    { key: 'Address Line 2', value: 'STAD2' },
    { key: 'Address Line 3', value: 'STAD3' },
    { key: 'City', value: 'STCITY' },
    { key: 'State', value: 'STST' },
    { key: 'Zipcode', value: 'STIP' },
  ],
}
