

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import TableHead from '../Reusable/TableHead'
import Fixed from '../Reusable/Fixed'
import Scrollable from '../Reusable/Scrollable'
import TableBody from '../Reusable/TableBody'
import Container from '../Reusable/Container'
import { returnValue } from './utils'
import Loading from '../Order/Tabs/Loading'

const columns = [
  { label: 'Product Code', value: returnValue('code') },
  { label: 'Product Name', value: returnValue('name') },
  { label: 'Product UN', value: returnValue('unNumber') },
  { label: 'Chemical UN', value: e => e?.chemical?.unNumber },
  { label: 'Chemical Name', value: e => e?.chemical?.name },
  // ,{
  //   label: 'Tanks',
  //   value: e => {
  //     if (!e.tanks || e.tanks.length <= 0) return
  //     if (e.tanks.length > 1) {
  //       return (
  //         <div>
  //           {e.tanks[0].tank_registration_no},{' '}
  //           <span style={{ color: '#3298FF' }}>+{e.tanks.length - 1} more</span>
  //         </div>
  //       )
  //     }
  //     return e.tanks[0].tank_registration_no
  //   },
  // }
]

function Products({ apiResult }) {
  const history = useHistory()
  return (
    <>
      <Container>
        <Fixed>
          <TableHead columns={columns} />
        </Fixed>
        {apiResult.isPending ? (
          <Loading m={4} />
        ) : (
          <>
            <Scrollable>
              <TableBody
                columns={columns}
                data={apiResult.data?.results}
                rowOnClick={row => {
                  history.push(`/tanks&products/product-details/${row.id}`)
                }}
              />
            </Scrollable>
          </>
        )}
      </Container>
    </>
  )
}

Products.propTypes = {
  apiResult: PropTypes.object,
}

Products.defaultProps = {
  apiResult: {},
}

export default Products
