const routes = {
  WELCOME: '/',
  REDIRECT_APP: '/redirect-app',
  AUTH: '/authorize',

  TENANT_INVITATION: '/tenant_invitation',

  COMPANY_DETAILS: '/company-details',

  TENANT_PERSONAL_DETAILS: '/personal-details',
  USER_PERSONAL_DETAILS: '/personal-details',

  TEAM_MEMBERS_LIST: '/team-members-list',
  USER_INVITATION: '/user_invitation',
  TENANT_PARTNER_INFO: '/tenant-partner-info',

  PRODUCT_LIST: '/products',
  PRODUCT_ADD: '/products/product-add',
  PHOTOS_COMMENTS_VIEW: '/photos-comments-view',

  INVITATION: '/invitations',
  SUPER_INVITATION_DETAILS: '/invitations/invitation-details',
  TENANTS: '/tenants',
  EDIT_TENANT_SUPERUSER: '/tenants/edit-tenant',
  SUPER_TENANT_DETAILS: '/tenants/tenant-details',
  CHEMICAL_LIST: '/chemicals',
  CHEMICAL_ADD: '/chemicals/chemical-add',
  CHEMICAL_DETAILS: '/chemicals/chemical-details',

  SUPER_USER_PROFILE: '/profile',
  USER_PROFILE: '/user-profile',
  COMPANY_PROFILE: '/company-profile',
  CHANGE_PASSWORD: '/change-password',
  PROFILE_EDIT: '/profile-edit',

  ORDER_LIST: '/orders',
  ORDER_DETAILS: '/orders/order-details',
  ADD_ORDER_PHOTOS: '/orders/add-order-photos',

  SHIPMENTS_LIST: '/shipments',
  ADD_SHIPMENT: '/shipments/shipment-add',
  SHIPMENT_DETAILS: '/shipments/shipment-details',
  EDIT_SHIPMENT: '/shipments/shipment-edit',

  CONNECTIONS: '/connections',
  CONNECTION_TENANT_PAGE: '/connections/connection-tenant-page',
  CONNECTED_TENANT_PAGE: '/connections/connected-tenant-page',
  CONNECTION_REQUEST_PAGE: '/connections/connection-request-page',
  CONNECTION_PARTNER_PAGE: '/connections/connection-partner-page',

  CHECK_LIST: '/checklist',
  CHECKLIST_INFO: '/checklist/checklist-info',
  CREATE_CHECKLIST: '/checklist/create-checklist',

  CONNECTION_PRODUCT_DETAILS: '/tanks&products/connection-product-details',
  TANKS_LIST: '/tanks&products',
  TANK_DETAILS: '/tanks&products/tank-details',
  PRODUCT_DETAILS: '/tanks&products/product-details',
  ADD_TANK: '/tanks&products/add-tank',
  UPDATE_TANK: '/tanks&products/edit-tank',

  CONTACT_PAGE: '/my-account/contact-page',
  TEAM_MEMBERS_INVITATION: '/my-account/team-members-invitation',
  TEAM_MEMBERS_EDIT: '/my-account/team-members-edit',
  LOCATION_DETAILS: '/my-account/location-details',
  EDITDETAILS_TENANT: '/edit-my-account',
  TEAM_MEMBERS_INFO: '/my-account/team-members-info',
  MY_COMPANY: '/my-account',

  CREATE_CONTACT: '/connections/create-contact',
}

export default routes
