import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import TableHead from '../Reusable/TableHead'
import Fixed from '../Reusable/Fixed'
import Scrollable from '../Reusable/Scrollable'
import TableBody from '../Reusable/TableBody'
import Container from '../Reusable/Container'
import { formatContactNo, formatLocation } from '../../helpers/util'
import Loading from '../Order/Tabs/Loading'
import { returnValue } from './utils'
import routes from '../../routes/route_contants'
import useAPILocal from '../../hooks/useAPILocal'
import locationAPI from '../../api/location'

const columns = [
  { label: 'Facility no.', value: returnValue('facility_number') },
  // {
  //   label: 'Name',
  //   value: returnValue('location_name'),
  // },
  {
    label: 'Address',
    value: e =>
      `${formatLocation(e, 'postal_address city state country postal_code')}`,
    maxWidth: 300,
    flex: 2,
  },
  {
    label: 'Contact No.',
    value: e => formatContactNo(e.phone_no),
  },
  {
    label: 'Primary Contact',
    value: e =>
      e?.primaryContact !== null
        ? `${e?.primaryContact?.name} ${e?.primaryContact?.surname}`
        : '-',
  },
]

function Locations({ locationReload }) {
  const locationList = useAPILocal(locationAPI.list)
  const history = useHistory()

  const handleRowClick = row => {
    history.push(`${routes.LOCATION_DETAILS}/${row.location_id}`)
  }

  useEffect(() => {
    locationList.request({ location_type: 'tenant_location' })
  }, [])
  useEffect(() => {
    if (locationReload) {
      locationList.request({ location_type: 'tenant_location' })
    }
  }, [locationReload])

  return (
    <Container>
      <Fixed>
        <TableHead columns={columns} />
      </Fixed>
      <Scrollable>
        {locationList.isPending && <Loading m={4} />}
        <TableBody
          rowOnClick={handleRowClick}
          columns={columns}
          data={locationList.data?.results}
        />
      </Scrollable>
    </Container>
  )
}

export default Locations
