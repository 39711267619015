import { fieldVisible, IAutoComplete, ISelect, IText } from './utils'
import chemicalAPI from '../../../../api/chemical'

function convertGrade(grade) {
  return grade
    ? grade
        .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
        .replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`)
    : undefined
}

const step1 = [
  {
    key: 'Product Code',
    visible: fieldVisible,
    children: [new IText('code', 'Product Code', '', ['required'])],
  },
  {
    key: 'Product UN Number',
    visible: fieldVisible,
    children: [
      new IAutoComplete(
        'unNumber',
        'Maps To Chemical (UN Number)',
        '',
        [],
        {
          src: chemicalAPI.newList,
          params: {},
        },
        option =>
          `${`${option.unNumber} - ${option.name}`} ${
            option.grade
              ? ` (${convertGrade(option.grade)}${
                  option.concentration ? `, ${option.concentration}%)` : ')'
                }`
              : option.concentration
              ? ` (${option.concentration}%)`
              : ''
          }`,
        '',
        {
          placeholder: 'Search for chemical by typing name or UN Number',
        }
      ),
    ],
  },
  {
    key: 'Product Name',
    visible: fieldVisible,
    children: [new IText('name', 'Product Name', '', ['required'])],
  },
  {
    key: 'Product Description',
    visible: fieldVisible,
    children: [new IText('description', 'Product Description', '')],
  },
  {
    key: 'Product Grade',
    visible: fieldVisible,
    children: [
      new ISelect('grade', 'Product Grade', '', undefined, [
        { value: 'membrane', label: 'Membrane' },
        { value: 'commercial', label: 'Commercial' },
        { value: 'diaphragm', label: 'Diaphragm' },
        { value: 'technical', label: 'Technical' },
        { value: 'chemical_grade', label: 'Chemical Grade' },
        { value: 'food', label: 'Food' },
        { value: 'pharmaceutical', label: 'Pharmaceutical' },
        { value: 'high_purity', label: 'High Purity' },
        { value: 'low_salt', label: 'Low Salt' },
      ]),
    ],
  },
  {
    key: 'Product Concentration',
    visible: fieldVisible,
    children: [new IText('concentration', 'Product Concentration (%)', '')],
  },

  {
    key: 'Sell',
    visible: fieldVisible,
    children: [
      {
        type: 'check',
        initial: false,
        label: 'Sell',
        showLabel: 'Do you sell this product?',
        name: 'sell',
        disabled: false,
      },
    ],
  },
  {
    key: 'Buy',
    visible: fieldVisible,
    children: [
      {
        type: 'check',
        initial: false,
        label: 'Buy',
        showLabel: 'Do you buy this product?',
        name: 'buy',
        disabled: false,
      },
    ],
  },
]

export default step1
