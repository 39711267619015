let timer = null

export function throttled(fun, seconds) {
  if (timer) clearTimeout(timer)
  timer = setTimeout(async () => {
    fun()
  }, seconds * 1000)
}

export function formatLocation(location, format) {
  return `${location?.postal_address}, ${location?.city}, ${location?.state?.name}, ${location?.country?.name}, ${location?.postal_code}`
}

export function formatContactNo(phone) {
  if (phone?.length >= 9 && !phone.includes('-')) {
    return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;
  }
  return phone;
}

export function copyToClipboard(str) {
  const el = document.createElement('textarea')
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export function dateFormatPattern() {
  return 'YYYY-MM-DD'
}