


import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useHistory } from 'react-router'
import TableHead from '../Reusable/TableHead'
import Scrollable from '../Reusable/Scrollable'
import TableBody from '../Reusable/TableBody'
import Container from '../Reusable/Container'
import Loading from '../Loading'
import routes from '../../routes/route_contants'

const columns = [
  {
    label: 'Name',
    value: e => e?.name,
    minWidth: 300,
  },
  {
    label: 'States',
    value: e => e?.states?.map(item => item.replaceAll('_', ' '))?.join(', '),
    minWidth: 180,
  },
  {
    label: 'Assigned To',
    value: e => e?.roles?.join(', '),
    minWidth: 180,
    flex: 2,
  },
]

function ChecklistList({ type, apiResult }) {
  const history = useHistory()

  const handleRowClick = row => {
    history.push(`${routes.CHECKLIST_INFO}/${row.id}`)
  }
  return (
    <Container>
      <Scrollable>
        <TableHead columns={columns} />
        {apiResult.isPending ? (
          <Loading m={4} />
        ) : (
          <TableBody
            columns={columns}
            data={apiResult.data}
            rowOnClick={handleRowClick}
          />
        )}
      </Scrollable>
    </Container>
  )
}

ChecklistList.propTypes = {
  type: PropTypes.string,
  apiResult: PropTypes.object,
}

ChecklistList.defaultProps = {
  type: 'tenant',
  apiResult: {},
}

export default ChecklistList
