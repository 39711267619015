
import ROUTES_PATH from './route_contants'
import {
  Welcome,
  Auth,
  RedirectApp,
  AddShipment,
  ShipmentDetails,
  EditShipment,
} from '../component'
import ChangePassword from '../component/Profile/ChangePassword'
import MyCompany from '../component/MyAccount'
import TeamMembersInfo from '../component/MyAccount/TeamMembers/TeamMembersInfo'
import TenantPartnerInfo from '../component/Connections/TenantPartnerInfo'
import ContactPage from '../component/MyAccount/Contacts/ContactPage'
import CreateContact from '../component/MyAccount/Contacts/CreateContact'
import TeamMembersInvite from '../component/MyAccount/TeamMembers/TeamMembersInvite'
import LocationDetails from '../component/Location/LocationDetails'
import AddOrderPhotos from '../component/Order/PhotosAndCommentsTab/AddPhotos'
// import InvitationDetails from '../component/Super/Invitations/InvitationDetails'
import TeamMembersEdit from '../component/MyAccount/TeamMembers/TeamMembersEdit'
// import CompanyInvitation from '../component/Super/Invitations/CompanyInvitation'

const Common = [
  {
    path: ROUTES_PATH.WELCOME,
    component: Welcome,
    isProtected: false,
  },
  {
    path: ROUTES_PATH.REDIRECT_APP,
    component: RedirectApp,
    isProtected: false,
  },
  {
    path: ROUTES_PATH.AUTH,
    component: Auth,
    isProtected: false,
  },
  {
    path: ROUTES_PATH.CHANGE_PASSWORD,
    component: ChangePassword,
  },
  {
    path: ROUTES_PATH.TEAM_MEMBERS_INFO,
    component: TeamMembersInfo,
    isProtected: true,
    queryParams: '/:user_id',
  },
  {
    path: ROUTES_PATH.TEAM_MEMBERS_EDIT,
    component: TeamMembersEdit,
    isProtected: true,
    queryParams: '/:user_id',
  },
  {
    path: ROUTES_PATH.TEAM_MEMBERS_INVITATION,
    component: TeamMembersInvite,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.LOCATION_DETAILS,
    component: LocationDetails,
    isProtected: true,
    queryParams: '/:location_id',
  },
  {
    path: ROUTES_PATH.MY_COMPANY,
    component: MyCompany,
    isProtected: true,
    queryParams: '/:entity?',
  },
  {
    path: ROUTES_PATH.TENANT_PARTNER_INFO,
    component: TenantPartnerInfo,
    isProtected: true,
    queryParams: '/:user_id',
  },
  {
    path: ROUTES_PATH.CONTACT_PAGE,
    component: ContactPage,
    isProtected: true,
    queryParams: '/:contact_id',
  },
  {
    path: ROUTES_PATH.CREATE_CONTACT,
    component: CreateContact,
    isProtected: true,
    queryParams: '',
  },
  {
    path: ROUTES_PATH.ADD_SHIPMENT,
    component: AddShipment,
    isProtected: true,
  },
  {
    path: ROUTES_PATH.ADD_ORDER_PHOTOS,
    component: AddOrderPhotos,
    isProtected: true,
    queryParams: '/:actionType/:type/:order_id/:index',
  },
  {
    path: ROUTES_PATH.SHIPMENT_DETAILS,
    component: ShipmentDetails,
    isProtected: true,
    queryParams: '/:shipment_id',
  },
  {
    path: ROUTES_PATH.EDIT_SHIPMENT,
    component: EditShipment,
    isProtected: true,
    queryParams: '/:shipment_id',
  },
  // {
  //   path: ROUTES_PATH.INVITATION,
  //   component: CompanyInvitation,
  //   isProtected: true,
  // },
  // {
  //   path: `${ROUTES_PATH.SUPER_INVITATION_DETAILS}/:invite_id`,
  //   component: InvitationDetails,
  //   isProtected: true,
  // },
]

export default Common
